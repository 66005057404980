<template>
  <div class="m-2 flex flex-col w-6/12 p-2">
    <label :for="name">{{ label }}</label>
    <input
      :name="name"
      :id="name"
      :type="type"
      :value="inputValue"
      :placeholder="placeholder"
      @input="handleChange"
      @blur="handleBlur"
      class="border-3 rounded-sm border-gray-100 bg-gray-100 h-8"
      :class="borderColor"
      :disabled="disabled"
    />

    <p
      :class="{ 'text-red-400': errorMessage }"
      v-show="errorMessage || meta.valid"
    >
      {{ errorMessage || successMessage }}
    </p>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useField } from 'vee-validate';

export default {
  props: {
    type: {
      type: String,
      default: 'text',
    },
    value: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    successMessage: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const {
      value: inputValue,
      errorMessage,
      handleBlur,
      handleChange,
      meta,
    } = useField(props.name, undefined, {
      initialValue: props.value,
    });

    const borderColor = computed(() => ({
      'border-green-400': !props.disabled && meta.valid,
      'border-red-400': !props.disabled && errorMessage,
    }));

    return {
      handleChange,
      handleBlur,
      errorMessage,
      inputValue,
      meta,
      borderColor,
    };
  },
};
</script>
